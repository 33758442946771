import React from 'react';
import Chatbot from './Chatbot';

function App() {
  return (
    <div>
      <Chatbot />
    </div>
  );
}

export default App;