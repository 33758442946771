import React, { useState } from 'react';
import axios from 'axios';

function Chatbot() {
  const [message, setMessage] = useState('');
  const [chatResponse, setChatResponse] = useState('');

  const sendMessage = async () => {
    try {
      const response = await axios.post('http://localhost:8000/chat', {
        message: message,
      });
      setChatResponse(response.data.response);
    } catch (error) {
      console.error('Error communicating with backend:', error);
      setChatResponse('Error communicating with the server.');
    }
  };

  return (
    <div>
      <h1>Chatbot Interface</h1>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Type your message here"
      />
      <button onClick={sendMessage}>Send</button>
      {chatResponse && (
        <div>
          <h2>Response:</h2>
          <p>{chatResponse}</p>
        </div>
      )}
    </div>
  );
}

export default Chatbot;